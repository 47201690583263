import React, {useState, useEffect} from "react";
import {NavLink} from "react-router-dom";
import {Menu} from "antd";
import CommonLogo from "assets/plutus.png";
import UserInfo from "./UserInfo";

const CommonNavbar = (props) => {
    const {
        onMenuSelectFn,
        selectedMenuItem,
        navbarItem,
    } = props;
    const [logo, setLogo] = useState(CommonLogo);

    const onMenuSelect = (e) => {
        if (e) {
            if (onMenuSelectFn) {
                onMenuSelectFn(e);
            }
        }
    };

    useEffect(() => {
        if (props.metaTagInfo && props.metaTagInfo.length > 0) {
            let metaInfo = props.metaTagInfo;
            metaInfo.map((obj) => {
                if (Object.entries(obj).length > 0) {
                    let meta = document.createElement("meta");
                    Object.entries(obj).map((innerObj) => {
                        if (innerObj) {
                            meta.setAttribute(innerObj[0], innerObj[1]);
                        }
                        return null;
                    });
                    document.getElementsByTagName("head")[0].appendChild(meta);
                }
                return null;
            });
        }
    }, [props.metaTagInfo]);

    return (
        <>
            {navbarItem && (
                <>
                    <Menu
                        // theme="dark"
                        mode="horizontal"
                        selectedKeys={[selectedMenuItem]}
                        style={{height: "48px"}}
                        onClick={onMenuSelect}
                    >
                        <Menu.Item key="home">
                            <NavLink style={{padding: 0}} to="">
                                <div>
                                    <img
                                        src={logo}
                                        width="40"
                                        height="40"
                                        alt={"DataOS"}
                                        style={{marginTop: "4px"}}
                                    />
                                </div>
                            </NavLink>
                        </Menu.Item>
                        {navbarItem.map(({label, name, to, children, icon}, index) => {
                            return (
                                <React.Fragment key={crypto.randomUUID}>
                                    {children ? (
                                        <Menu.SubMenu key={name} title={label} icon={icon}>
                                            {children.map(({label, name, to, icon}, index) => {
                                                return (
                                                    <Menu.Item key={name} icon={icon}>
                                                        <NavLink style={{padding: 0}} to={to}>
                                                            {label}
                                                        </NavLink>
                                                    </Menu.Item>
                                                );
                                            })}
                                        </Menu.SubMenu>
                                    ) : (
                                        <Menu.Item key={name} icon={icon}>
                                            <NavLink style={{padding: 0}} to={to}>
                                                {label}
                                            </NavLink>
                                        </Menu.Item>
                                    )
                                    }
                                </React.Fragment>
                            );
                        })}
                        <Menu.Item
                            key={"userinfo"}
                            style={{marginLeft: "auto", padding: "0px"}}
                        >
                            <UserInfo toggleTheme={props.toggleTheme}/>
                        </Menu.Item>
                    </Menu>
                </>
            )}
        </>
    );
};

export default React.memo(CommonNavbar);
