import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { APP_ENV } from './utils/Const'
import App from 'app';
import './css/Custom.scss';

function noop() {}
if (APP_ENV === 'production') {
    console = console || {};
    console.log = noop;
    console.warn = noop;
    // console.error = noop; //to enable errors in production env
}

const root = ReactDOM.createRoot(document.getElementById('root'))
export {}
root.render(<App/>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
