import AppContainer from './containers/AppContainer';
import {Provider} from 'react-redux';
import store from './store';
import {BrowserRouter as Router} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ModernAuth} from "modern-auth";

function App() {

    const [config, setConfig] = useState(null);
    const [authInfoUrl, setAuthInfoUrl] = useState("");

    const fetchConfigDetailsOnly = (details) => {
        return Object.keys(details)
            .filter(
                (key) => !key.includes("heimdallBaseUrl")
            )
            .reduce((obj, key) => {
                return Object.assign(obj, {
                    [key]: details[key],
                });
            }, {});
    };

    useEffect(() => {
        let url = "api/config";
        axios
          .get(url)
          .then((res) => {
            if (res.data) {
              setAuthInfoUrl(res.data.heimdallBaseUrl)
              setConfig(fetchConfigDetailsOnly(res.data));
            }
          })
          .catch((error) => {
            if (error.response != null) {
            }
          });
      }, []);

    return (
        <>
            {config && (
                <Provider store={store}>
                    <Router basename={"prime"}>
                        <ModernAuth oidc_config={config}>
                            <AppContainer authInfoUrl={authInfoUrl}/>
                        </ModernAuth>
                    </Router>
                </Provider>
            )}
        </>
    );
}

export default App;
