import React from "react";
import {LoadingOutlined} from "@ant-design/icons";

const Loader = (props) => {
    return (
        <div>
            {props.isSmallLoading ? (
                <div
                    style={{
                        opacity: "0.3",
                        fontSize: "1rem",
                        padding: `${props.padding}`,
                    }}
                >
                    <LoadingOutlined style={{marginRight: "10px"}}/>
                    {props.msg ? props.msg : "Loading..."}
                </div>
            ) : (
                <div
                    style={{
                        opacity: "0.3",
                        fontSize: "2.6rem",
                        fontWeight: "300",
                        padding: `${props.padding}`,
                    }}
                >
                    <LoadingOutlined style={{marginRight: "10px"}}/>
                    {props.msg ? props.msg : "Loading..."}
                </div>
            )}
        </div>
    );
};

export default Loader;
