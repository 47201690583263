import React, { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { Menu, Dropdown } from "antd";
import { useAuth, useLocalStorage } from "modern-auth";

const UserInfo = (props) => {
    const auth = useAuth();
    const [value, setValue] = useLocalStorage('userInfo', '')
    const [currentTheme, setCurrentTheme] = useState(
        localStorage.getItem("theme")
    );
    const getCurrentTheme = () => {
        setCurrentTheme(localStorage.getItem("theme"));
    };

    const { user, appVersion } = useSelector(
        (state) => ({
            user: state.commonReducer.user,
            appVersion: state.commonReducer.appVersion,
        }),
        shallowEqual
    );

    const signOut = async () => {
        if (auth.isAuthenticated) {
            auth.removeUser();
            localStorage.removeItem('userInfo')
        }
    };

    const menu = value && (
        <Menu style={{ maxWidth: "250px" }}>
            <Menu.Item key="0">
                <a href={`/home/profile/${value?.id}`} target="_blank" rel="noopener noreferrer">
                    {value?.name}
                    <div>
                        <small>{value?.email}</small>
                    </div>
                </a>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1" className={"userInfo"} onClick={() => signOut()}>
                <small>{"Log out"}</small>
            </Menu.Item>
            <Menu.Divider />
            {
                appVersion &&
                <Menu.Item key="2" disabled>
                    {
                        appVersion &&
                        <small style={{display: 'block'}}>{appVersion}</small>
                    }
                </Menu.Item>
            }
        </Menu>
    );

    if (value) {
        return (
            <div className="user-info-outer">
                <Dropdown
                    overlay={menu}
                    trigger={["click"]}
                    placement="bottomRight"
                    className="user-info-dropdown"
                    key={'user-avatar-drop-down'}
                >
                    <a
                        className="pointer"
                        target="_blank"
                        onClick={(e) => e.preventDefault()}
                        style={{ display: "flex", height: "48px", alignItems: "center", justifyContent: "center" }}
                    >
                        <Avatar
                            name={value.name}
                            size="30"
                            maxInitials={2}
                            src={`data:image/png;base64, ${value.avatar_base64}`}
                            round={true}
                            color={"#4a8ac3"}
                        />
                    </a>
                </Dropdown>
            </div>
        );
    } else {
        return <> no data </>;
    }
};

export default React.memo(UserInfo);
