import React, {useState, useEffect, lazy, Suspense} from "react";
import {useDispatch} from "react-redux";
import {
    Routes,
    Route,
    Navigate,
    useLocation,
    useNavigate,
  } from "react-router-dom";
import {Scrollbars} from "react-custom-scrollbars";
import Loader from "../common/loader";
import * as CONST from "../utils/Const";
import {Layout, Divider, notification} from "antd";
import CommonNavbar from "../common/navbar";
import * as commonAction from "../actions/CommonAction";
import { fetchHeimdallDetails, useAuth, useLocalStorage, Login } from 'modern-auth';
import axios from 'axios';
import LoginDark from '../assets/plutus-dark.png'

const {Header, Content} = Layout;

const stylesheets = {
    light: "https://cdnjs.cloudflare.com/ajax/libs/antd/4.23.6/antd.min.css",
    dark: "https://cdnjs.cloudflare.com/ajax/libs/antd/4.23.6/antd.dark.min.css",
};

const createStylesheetLink = () => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.id = "antd-stylesheet";
    document.head.appendChild(link);
    return link;
};

const getStylesheetLink = () =>
    document.head.querySelector("#antd-stylesheet") || createStylesheetLink();

const systemTheme = () =>
    window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light";

const getTheme = () => localStorage.getItem("theme") || systemTheme();

const setTheme = (Theme) => {
    localStorage.setItem("theme", Theme);
    getStylesheetLink().href = stylesheets[Theme];
    document.body.setAttribute("class", Theme);
};

const Prime = lazy(() => import("../components/prime"));
const OrganizationAdmin = lazy(() => import("../components/prime/organizations/index"));
const AccountsAdmin = lazy(() => import("../components/prime/accounts/index"));
const ProductAdmin = lazy(() => import("../components/prime/products/index"));
const InstancesAdmin = lazy(() => import("../components/prime/instances/index"));
const ForgeAdmin = lazy(() => import("../components/prime/forge/index"));

const AppContainer = (props) => {
    // const [userInfo, setUserInfo] = useLocalStorage("userInfo", "");
    const [value, setValue] = useLocalStorage('userInfo', '')
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate()
    const [message, setMessage] = useState(null)

    const [currentTheme, setCurrentTheme] = useState(
        localStorage.getItem("prime-theme")
    );

    const getCurrentTheme = () => {
        setCurrentTheme(localStorage.getItem("prime-theme"));
    };

    useEffect(() => setTheme("dark"), []);

    // const {user} = useSelector(
    //     (state) => ({
    //         user: state.commonReducer.user,
    //     }), shallowEqual
    // );
    const dispatch = useDispatch();

    useEffect(() => {
        document.body.setAttribute("class", currentTheme);
    }, [currentTheme]);

    const toggleTheme = () => {
        getCurrentTheme();
    };

    useEffect(() => setTheme(getTheme()), []);

    const loadAuth = async () => {
        const userDetails = await fetchHeimdallDetails(
            auth.user.access_token
        );

        if (userDetails === 401) {
            auth.removeUser()
            localStorage.removeItem('userInfo')
            setMessage('Unauthorized')
          } else {
            await setValue(userDetails)
          }
        // await setUserInfo(userDetails);
        if (auth.user && auth.user.state && auth.user.state.targetHref) {
            // window.open(auth.user.state.targetHref, "_self");
            let redirectUrl = '/' + auth.user.state.targetHref.split('/').slice(2).join('/')
            navigate(redirectUrl)
        }
    };

    useEffect(() => {
        if (auth.isAuthenticated) {
            loadAuth();
        }
    }, [auth.isAuthenticated]);

    useEffect(() => {
        if (value && value.accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${value.accessToken}`;
            value && getConfigDetails();
        } else {
            axios.defaults.headers.common['Authorization'] = '';
            delete (axios.defaults.headers.common['Authorization']);
        }

    }, [value])

    useEffect(() => {
        const checkLocalstorage = (event) => {
            // if (
            //     (event.key === "userInfo") &&
            //     (event.newValue === null || event.oldValue === null)
            // ) {
            //     event.currentTarget.location.reload();
            // }
            if (event.key === 'userInfo' && (event.newValue === null || event.oldValue === null)) {
                (event.currentTarget).location.reload();
              }
        };
        window.addEventListener("storage", checkLocalstorage);
        return () => {
            window.removeEventListener("storage", checkLocalstorage);
        };
    }, []);


    const getConfigDetails = () => {
        let url = "api/app";
        dispatch(commonAction.fetchConfigDetails());
        axios
            .get(url)
            .then((res) => {
                dispatch(commonAction.fetchConfigDetailsSuccess(res.data));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    notification.warning({
                        message: CONST.AUTHENTICATION_FAILURE_MESSAGE,
                        placement: "BottomRight",
                    });
                } else if (error.response && error.response.status === 403) {
                    notification.warning({
                        message: CONST.AUTHORIZATION_FAILURE_MESSAGE,
                        placement: "BottomRight",
                    });
                }
            });
    };

    useEffect(() => {
        if (CONST.APP_ENV === "development") {
            let hostName = "127.0.0.1";
            if (
                window.location.hostname &&
                window.location.hostname.includes("localhost")
            ) {
                window.location.hostname = hostName;
            }
        }
    }, []);

    const NavBarItem = [
        {name: "accounts", label: "Accounts", to: "/accounts"},
        {name: "organizations", label: "Organizations", to: "/organizations"},
        {name: "instances", label: "Instances", to: "/instances"},
        {name: "forge", label: "Forge", to: "/forge"},
        {name: "products", label: "Products", to: "/products"},
    ];

    const setActiveMenuValue = (e) => {
        setSelectedMenuItem(null);
        if (e && e.key) {
            setSelectedMenuItem(e.key);
        }
    };

    // const setSelectedMenuFromLocation = () => {
    //     if (location && location.pathname) {
    //         let path = location.pathname;
    //         if (path.includes(`users`)) setSelectedMenuItem("users");
    //         else if (path.includes(`roles`)) setSelectedMenuItem("roles");
    //         else if (path.includes(`grants`)) setSelectedMenuItem("grants");
    //         else if (path.includes(`use-cases`)) setSelectedMenuItem("use-cases");
    //         else if (path.includes(`providers`)) setSelectedMenuItem("providers");
    //         else if (path.includes(`policies`)) setSelectedMenuItem("policies");
    //         else if (path.includes(`tag-namespaces`)) setSelectedMenuItem("tagNamespaces");
    //         else if (path.includes(`tags`)) setSelectedMenuItem("tags");
    //     }
    // }
    //
    // useEffect(() => {
    //     if (location.pathname) setSelectedMenuFromLocation();
    // }, [location.pathname]);

    const Page404 = () => {
        const location = useLocation();
        return (
            <div className="not-found">
                <Divider
                    style={{width: "633px", minWidth: "633px", margin: "20px auto 0"}}
                />
                <h1>
          <span
              style={{fontSize: "7rem", lineHeight: "normal", opacity: 0.2}}
          >
            Not Found
          </span>
                </h1>
                <Divider
                    style={{width: "633px", minWidth: "633px", margin: "0 auto 20px"}}
                />
                <h2 style={{fontSize: "32px", fontWeight: 100}}>
                    No matching resource found for
                </h2>
                <code>
          <span style={{fontSize: "13.3px", fontFamily: "Menlo"}}>
            {location.pathname}
          </span>
                </code>
            </div>
        );
    };

    return (
        <Scrollbars
            style={{
                height: "calc(100vh - 48px)",
            }}
            renderView={(props) => (
                <div
                    {...props}
                    style={{
                        ...props.style,
                        overflowX: "hidden",
                        marginBottom: 0,
                    }}
                    id="app-scrollbar"
                />
            )}
            autoHide
        >
            <Layout style={{minHeight: "100%"}} className="layout">
                {auth.isLoading ? (
                    <Loader padding={'50px'} msg={'Loading...'}/>
                ) : (
                    <>
                        {!auth.isAuthenticated && (
                            <Login logo={LoginDark} message={message} />
                        )}
                        {auth.isAuthenticated &&
                            value && (
                                <>
                                    <Header
                                        className="header"
                                        style={{
                                            top: 0,
                                            position: "fixed",
                                            width: "100%",
                                            padding: "0",
                                            height: "48px",
                                            lineHeight: "48px",
                                        }}
                                    >
                                        <CommonNavbar
                                            navbarItem={NavBarItem}
                                            toggleTheme={toggleTheme}
                                            onMenuSelectFn={setActiveMenuValue}
                                            selectedMenuItem={selectedMenuItem}
                                        />
                                    </Header>
                                    <Suspense fallback={<></>}>
                                        <Content style={{padding: "20px", height: "100%"}}>
                                            <Routes>
                                                <Route
                                                    path={"/"}
                                                    element={auth.isAuthenticated &&
                                                        (<Prime {...props} />)
                                                    }
                                                />
                                                <Route
                                                    path="/prime"
                                                    element={
                                                        auth.isAuthenticated && (
                                                        <Prime
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/organizations"}
                                                    element={
                                                        auth.isAuthenticated && (<OrganizationAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/organizations/:id"}
                                                    element={auth.isAuthenticated &&
                                                        (<OrganizationAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/organizations/:id/licenses/:licenseId"}
                                                    element={auth.isAuthenticated &&
                                                        (<OrganizationAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/accounts"}
                                                    element={auth.isAuthenticated &&
                                                        (<AccountsAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/accounts/:id"}
                                                    element={auth.isAuthenticated &&
                                                        (<AccountsAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/products"}
                                                    element={auth.isAuthenticated &&
                                                        (<ProductAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/products/:id"}
                                                    element={auth.isAuthenticated &&
                                                        (<ProductAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/instances"}
                                                    element={auth.isAuthenticated &&
                                                        (<InstancesAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/instances/:id"}
                                                    element={auth.isAuthenticated &&
                                                        (<InstancesAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/instances/:id/:ierId"}
                                                    element={auth.isAuthenticated &&
                                                        (<InstancesAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/forge"}
                                                    element={auth.isAuthenticated &&
                                                        (<Navigate to={`/forge/tasks`}/>)
                                                    }
                                                />
                                                <Route
                                                    path={"/forge/tasks"}
                                                    element={auth.isAuthenticated &&
                                                        (<ForgeAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/forge/workpieces"}
                                                    element={auth.isAuthenticated &&
                                                        (<ForgeAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/forge/templates"}
                                                    element={auth.isAuthenticated &&
                                                        (<ForgeAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/forge/configs"}
                                                    element={auth.isAuthenticated &&
                                                        (<ForgeAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/forge/tasks/:taskId"}
                                                    element={auth.isAuthenticated &&
                                                        (<ForgeAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                <Route
                                                    path={"/forge/workpieces/:workpieceId"}
                                                    element={auth.isAuthenticated &&
                                                        (<ForgeAdmin
                                                            selectedMenuItem={selectedMenuItem}
                                                        />)
                                                    }
                                                />
                                                
                                                <Route
                                                    path={"/auth/oidc/callback"}
                                                    element={
                                                        <Prime {...props} />
                                                    }
                                                />
                                                <Route
                                                    path="*"
                                                    element={
                                                        <Page404/>
                                                    }
                                                />
                                            </Routes>
                                        </Content>
                                    </Suspense>
                                </>
                            )}
                    </>
                )}
            </Layout>
        </Scrollbars>
    );
};

export default AppContainer;
