/**
 * @author Hanuman Singh Parihar
 * Date: Dec 16, 2021
 * Description: COMMON REDUCER FOR CONFIGS!
 * 
 */
 import * as CONST from '../utils/Const';

 const initialState = {
     appVersion: '',
     installVersion: '',
     config: null,
     user: null
 };
 
 export default function (state = initialState, action) {
     switch (action.type) {
     case CONST.FETCH_CONFIG_DETAILS:
         return {
             ...state,
             appVersion: '',
             installVersion: '',
             config: null,
             user: null
         };
     case CONST.FETCH_CONFIG_DETAILS_SUCCESS:
         return {
             ...state,
             appVersion: action.payload.version?action.payload.version:'',
             installVersion: action.payload.installVersion?action.payload.installVersion:'',
             user: action.payload.currentUser?action.payload.currentUser:null,
             config: action.payload.config?action.payload.config:null,
         };
     case CONST.FETCH_CONFIG_DETAILS_FAILURE:
         return {
             ...state,
             appVersion: '',
             installVersion: '',
             config: null,
             user: null
         };
     case CONST.RESET_CONFIG_DETAILS:
         return {
             ...state,
             appVersion: '',
             installVersion: '',
             config: null,
             user: null
         };
     default:
         return state;
     }
 }
 