export const APP_ENV = process.env.NODE_ENV;

export const AUTHENTICATION_FAILURE_MESSAGE = 'You are not authenticated, please login';
export const AUTHORIZATION_FAILURE_MESSAGE = 'You are not authorized to use this feature';
export const SERVER_ERROR_MESSAGE = 'Server hiccup, please try again in a few moments, if the issue persists please contact support';
export const NOT_FOUND = 'That item is not found';
export const ERROR_MESSAGE='Something went wrong';
export const SERVER_NOT_FOUND= 'Not found'

export const FETCH_CONFIG_DETAILS = "FETCH_CONFIG_DETAILS";
export const RESET_CONFIG_DETAILS = "RESET_CONFIG_DETAILS";
export const FETCH_CONFIG_DETAILS_SUCCESS = "FETCH_CONFIG_DETAILS_SUCCESS";
export const FETCH_CONFIG_DETAILS_FAILURE = "FETCH_CONFIG_DETAILS_FAILURE";


export const useCaseFormat=`
id: **
name: Add name
description: Add description
category: governance 
authorization_atoms: 
  - Add atom id`

export const createProviderFormat = `
version: **
id: **
name: Add provider name
description: Add provider description
authorization_atoms:
    # you can add multiple atoms
  - id: Add atom id
    description: Add description 
    predicate: Add predicate
    tags:
        # you can add multiple tags
      - Add tag for atom`