/**
 * @author Hanuman Singh Parihar
 * Date: Dec 16, 2021
 * Description: COMMON ACTIONS FOR CONFIGS!
 * 
 */
 import * as CONST from '../utils/Const';
 
 export function fetchConfigDetails() {
     return { 
         type: CONST.FETCH_CONFIG_DETAILS, 
     };
 }
 
 export function fetchConfigDetailsSuccess(data) {
     return { 
         type: CONST.FETCH_CONFIG_DETAILS_SUCCESS,
         payload: data,
     };
 }
 
 export function fetchConfigDetailsFailure(error) {
     return { 
         type: CONST.FETCH_CONFIG_DETAILS_FAILURE,
         payload: error
     };
 }
 
 export function resetConfigDetails() {
     return { 
         type: CONST.RESET_CONFIG_DETAILS, 
     };
 }